<template>
  <div id="app">
    <!-- <v-scale-screen width="1920" height="1080" :fullScreen="true"> -->
    <router-view />
    <!-- </v-scale-screen> -->
    <!-- <router-view v-if="$route.path !== '/agro'"></router-view> -->
  </div>
</template>
<script>
import autofit from "autofit.js";
import VScaleScreen from "v-scale-screen";
document.addEventListener("gesturestart", function (event) {
  event.preventDefault();
});

export default {
  name: "App",
  components: {
    VScaleScreen,
  },
  created() {},
  mounted() {
    // autofit.init(
    //   {
    //     designHeight: 1080, //项目初始的分辨率，一般就是你开发电脑的屏幕分辨率
    //     designWidth: 1920, //项目初始的分辨率，一般就是你开发电脑的屏幕分辨率
    //     renderDom: "#app", //App最外层的id名，一般都为app
    //     resize: true, //是否监听resize事件，默认是 true
    //   },
    //   false
    // );
  },
};
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: rgba(64, 96, 112, 0.2) !important;
}
</style>
